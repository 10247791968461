import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import {
  AddWorkFlow,
  Layout,
  MultiStepForm,
  SendInvite,
  UpdateWorkFlow,
} from "./components";
import { Login } from "./pages/Login";
import PrivateRoute from "./components/layout/PrivateRoute";
import {
  CreateClient,
  Workflows,
  Clients,
  UserDashbord,
  Agents,
  CallAudits,
  Escalations,
} from "./pages";
import { useDispatch } from "react-redux";
import { getAllClients } from "./api/clients";
import { toast } from "react-toastify";
import { setAllClients } from "./redux/clientSlice";
import { useEffect, useRef } from "react";
function App() {
  const dispatch = useDispatch();
  let isFetch = useRef(true);
  const navigate = useNavigate();
  const fetchAllClients = async () => {
    try {
      const res = await getAllClients();
      if (res.status === 200) {
        toast.success("Clients Fetched Successfully");
        dispatch(setAllClients(res?.data));
      } else {
        if (res.response.status === 401) {
          navigate("/login");
          toast.error("Unauthorized");
        }
      }
    } catch (error) {
      console.error("Error fetching clients", error);
      toast.error("Error fetching clients");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("userDetails");
    if (token) {
      if (isFetch) {
        isFetch = false;
        fetchAllClients();
      }
    }
  }, []);

  return (
    <Routes>
      <Route path="/workflows/create" element={<AddWorkFlow />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/create-client"
        element={
          <PrivateRoute>
            <Layout>
              <CreateClient />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/clients"
        element={
          <PrivateRoute>
            <Layout>
              <Clients />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/setup-new-agent"
        element={
          <PrivateRoute>
            <Layout>
              <MultiStepForm />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/agents"
        element={
          <PrivateRoute>
            <Layout>
              <Agents />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <Layout>
              <UserDashbord />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout>
              <Workflows />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/workflows/update-workflow/:id"
        element={
          <PrivateRoute>
            <Layout>
              <UpdateWorkFlow />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/workflows/add-workflow"
        element={
          <PrivateRoute>
            <Layout>
              <AddWorkFlow />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/audit-log"
        element={
          <PrivateRoute>
            <Layout>
              <CallAudits />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/escalations"
        element={
          <PrivateRoute>
            <Layout>
              <Escalations />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/invitations"
        element={
          <PrivateRoute>
            <Layout>
              <SendInvite />
            </Layout>
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
